/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useContext, useEffect, useState } from 'react';
import { Link } from '../useSiteData';
import { SiteContext } from './ContextProvider';
import style from './Nav.module.css';

const Header: React.FunctionComponent = () => {
    const { links } = useContext(SiteContext);
    const [active, setActive] = useState('#home');
    const [navState, setNavState] = useState('');

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY >= window.innerHeight - 30) {
                setNavState(style.filled);
            } else if (window.scrollY >= 10) {
                setNavState(style.fade);
            } else {
                setNavState('');
            }
        });
    }, []);

    return (
        <nav className={`${style.navWrap} ${navState}`}>
            {links.map(({ title, id }: Link) => (
                <div className={active === id ? style.current : ''} onClick={() => setActive(id)}>
                    <a href={id}>{title}</a>
                </div>
            ))}
        </nav>
    );
};

export default Header;
