import React from 'react';
import useSiteData, { Data } from '../useSiteData';

interface Props {
    children: React.ReactNode;
}

export const SiteContext = React.createContext({} as Data);

const ContextProvider : React.FunctionComponent<Props> = ({children}: Props) =>  {

    const data: Data | undefined = useSiteData();
    
    if(!data) return ( 
        <div className="App">
          Loading....
        </div>
    );
        
    return (
        <SiteContext.Provider value={data}>
            {children}
        </SiteContext.Provider>
    );
  
}

export default ContextProvider;
