import React, { useContext } from 'react';
import jojo from '../images/jojo.png';
import luna from '../images/luna.png';
import { SiteContext } from './ContextProvider';
import style from './Header.module.css';
import Nav from './Nav';

const Header: React.FunctionComponent = () => {
    const { title, description, social } = useContext(SiteContext).main;
    return (
        <header id="home" className={style.header}>
            <Nav />

            <div className={style.banner}>
                <div className={style.bannerText}>
                    <img alt="luna" src={luna} className={style.luna} />
                    <img alt="jojo" src={jojo} className={style.jojo} />
                    <h1>{title}</h1>
                    <h3>{description}</h3>

                    {/* <div className="social">
                        {social.map((item) => (
                            <a href={item.url}>
                                <i className={item.className}>{item.name}</i>
                            </a>
                        ))}
                    </div> */}
                </div>
            </div>

            <p className="scrolldown">
                <a className="smoothscroll" href="#about">
                    <i className="icon-down-circle"></i>
                </a>
            </p>
        </header>
    );
};

export default Header;
