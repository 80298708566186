import '@fortawesome/fontawesome-free/js/all.js';
import React from "react";
// import "./App.css";
import About from "./Components/About";
import Contact from "./Components/Contact";
import ContextProvider from './Components/ContextProvider';
import Footer from "./Components/Footer";
import Header from './Components/Header';


const App: React.FunctionComponent = () => {
  

    return (
     
<ContextProvider>

          <div className="App">
          <Header/>
          <About/>
          <Contact/>
          <Footer/>
          </div>
</ContextProvider>

    );
}

export default App;
