import React from 'react';
import "./Footer.css";

const Footer : React.FunctionComponent = () =>  {


    return (
      <footer>

     <div className="row">
        <div className="twelve columns">
           <ul className="social-links">
              {/* {networks} */}
           </ul>

           <ul className="copyright">
              <li>&copy; Copyright 2001 Nogglings</li>
              <li>Design by <a title="Styleshout" href="#">Christopher Berge Hove</a></li>
           </ul>

        </div>
        <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open"></i></a></div>
     </div>
  </footer>
    );
  
}

export default Footer;
