import React from 'react';
import chris from '../images/chris-temp.png';
import gaute from '../images/gaute-temp.png';
import style from './Contact.module.css';

const Contact: React.FunctionComponent = () => {
    return (
        <section id="contact" className={style.section}>
            <div className={style.wrapper}>
                <div className={style.contact}>
                    <img alt="Christopher Berge Hove" src={chris} className={style.contactImg} />
                    <h2>Christopher Hove</h2>
                    <div>Some description of me</div>
                    <div>000 00 000</div>
                </div>
                <div className={style.contact}>
                    <img alt="Gaute Ådnesen" src={gaute} className={style.contactImg} />
                    <h2>Gaute Ådnesen</h2>
                    <div>Some description of me</div>
                    <div>000 00 000</div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
