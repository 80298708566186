import { useEffect, useState } from "react";


export interface Data { 
    main: {
        title: string;
       description: string;
       social: Social[];
    },
    links: Link[]
  }
  
  export interface Social {
    name: string;
    url: string;
    className: string;
  }

  export interface Link {
    title: string,
    id: string,
  }

const useSiteData = ()  => {
    const [data, setData] = useState<Data | undefined>();

useEffect(()=> {
    async function readSiteDataJson(){
        try {
            const response =  await fetch('/siteData.json')
            console.log(response)
            if (!response.ok) {
                throw new Error("HTTP error " + response.status);
            }
            setData(await response.json() as Data);
        } catch (error) {
            console.log(error)
            
        }
    }

    if (!data) {
        readSiteDataJson()
    }
}, [data])

    return data;
}


 export default useSiteData;